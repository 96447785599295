import './style.css'
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import Scrollbar from 'smooth-scrollbar'
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { initializeApp } from 'firebase/app';
import { getFirestore, addDoc, collection } from "firebase/firestore";


gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)

let bodyScrollBar
const scroller = document.querySelector('.scroller')

const touch = () => {
    return ('ontouchstart' in window) ||
        (navigator.maxTouchPoints > 0) ||
        (navigator.msMaxTouchPoints > 0);
}
if (touch() == false) {
    bodyScrollBar = Scrollbar.init(scroller, {
        damping: 0.07,
        continuousScrolling: false,
        delegateTo: document
    });

    ScrollTrigger.scrollerProxy(".scroller", {
        scrollTop(value) {
            if (arguments.length) {
                bodyScrollBar.scrollTop = value;
            }
            return bodyScrollBar.scrollTop;
        }
    });

    bodyScrollBar.addListener(ScrollTrigger.update);

}

ScrollTrigger.defaults({ scroller: scroller });


gsap.from("header", {
    delay: 0,
    duration: 1.2,
    opacity: 0,
    scale: 0.8,
    ease: "power2.out"
});

gsap.fromTo("h2 span", {
    opacity: 0,
    yPercent: -25,
    xPercent: -25,
}, {
    yPercent: -50,
    xPercent: -50,
    opacity: 1,
    duration: 1.2,
    stagger: 0.4,
    delay: 1,
    ease: "power2.out"
});

gsap.to(".hero-img", {
    scrollTrigger: {
        trigger: "header",
        start: "top top",
        end: "bottom top",
        scrub: true
    },
    yPercent: 10
});


ScrollTrigger.matchMedia({
    "(min-aspect-ratio: 4/3)": function() {

        gsap.utils.toArray(".floor-blueprint").forEach(img => {
            gsap.to(img, {
                scrollTrigger: {
                    trigger: img.parentElement,
                    pin: img,
                    start: "top top",
                    end: "bottom bottom",
                    toggleActions: "play reverse play reverse"
                },
                scale: 1.05,
                duration: 0.5
            });
        });

        gsap.fromTo(".pool div", {
            scale: 0.9
        }, {
            scrollTrigger: {
                trigger: ".pool",
                pin: true,
                start: "top top",
                end: "+=200",
                scrub: 0.2,
                // markers: true
            },
            scale: 1
        });

    },
    "(max-aspect-ratio: 4/3)": function() {
        console.log('Hellooo!')
    }
})

gsap.utils.toArray(".img-cont").forEach(imgCont => {
    let tl = gsap.timeline({
        scrollTrigger: {
            trigger: imgCont.parentElement,
            start: "50% 75%",
            // markers: true
        }
    })
    tl.fromTo(imgCont, { yPercent: 100 }, { yPercent: 0, ease: "power2.out", duration: 1.2 })
        .fromTo(imgCont.firstChild, { yPercent: -100 }, { yPercent: 0, ease: "power2.out", duration: 1.2 }, "<")
});

gsap.utils.toArray(".parallax").forEach(img => {
    img.style.scale = "1.2";
    img.parentElement.style.overflow = "hidden";
    img.parentElement.style.position = "relative";
    // img.parentElement.style.touchAction = "none";

    gsap.fromTo(img, {
        yPercent: -10,
        scale: 1.2
    }, {
        yPercent: 10,
        scale: 1.2,
        scrollTrigger: {
            trigger: img.parentElement,
            start: "top bottom",
            end: "100%+=200 top",
            scrub: true,
            // markers: true
        }
    });

});

// Detect if a link's href goes to the current page
function getSamePageAnchor(link) {
    if (
        link.protocol !== window.location.protocol ||
        link.host !== window.location.host ||
        link.pathname !== window.location.pathname ||
        link.search !== window.location.search
    ) {
        return false;
    }

    return link.hash;
}

// Scroll to a given hash, preventing the event given if there is one
function scrollToHash(hash, e) {
    const elem = hash ? document.querySelector(hash) : false;
    if (elem) {
        if (e) e.preventDefault();

        if (touch()) {
            gsap.to(scroller, { duration: 2, scrollTo: elem });
        } else {
            bodyScrollBar.scrollIntoView(elem)
        }
    }
}

// If a link's href is within the current page, scroll to it instead
document.querySelectorAll('a[href]').forEach(a => {
    a.addEventListener('click', e => {
        scrollToHash(getSamePageAnchor(a), e);
    });
});

// Scroll to the element in the URL's hash on load
scrollToHash(window.location.hash);

// // Only necessary to correct marker position - not needed in production
// if (document.querySelector('.gsap-marker-scroller-start')) {    
//   const markers = gsap.utils.toArray('[class *= "gsap-marker"]'); 

//   bodyScrollBar.addListener(({ offset }) => {  
//     gsap.set(markers, { marginTop: -offset.y })
//   });
// }

// Forms animation
const inputAnimation = ["name", "tel", "email"]
inputAnimation.forEach(e => {
    const input = document.querySelector(`#${e}`)
    const label = document.querySelector(`#${e}Label`)
    input.addEventListener("focus", () => {
        label.classList.add("filled")
    })
    input.addEventListener("blur", () => {
        if (input.value != "") return
        label.classList.remove("filled")
    })
});


// Leads
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDLiaSvPtXhfspn9w7_A1yfHonGCp1_OnQ",
    authDomain: "villas-altavista.firebaseapp.com",
    databaseURL: "https://villas-altavista-default-rtdb.firebaseio.com",
    projectId: "villas-altavista",
    storageBucket: "villas-altavista.appspot.com",
    messagingSenderId: "486771837202",
    appId: "1:486771837202:web:90515c926e74e5e10f4bcb",
    measurementId: "G-RKDBL3X7JK"
};

// Get form values
const form = document.getElementById("form")
const formInputs = ["name", "tel", "email", "credit"]

// Get date
const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];
const dateObj = new Date();
const month = monthNames[dateObj.getMonth()];
const day = String(dateObj.getDate()).padStart(2, '0');
const year = dateObj.getFullYear() - 2000;
const today = `${day}-${month}-${year}`;

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore();

const submit = document.getElementById("submit")
submit.addEventListener("click", () => {
    const formValues = []

    formInputs.forEach(e => {
        const value = form.elements[e].value;
        const label = document.getElementById(`${e}Label`)
        if (!value) {

            gsap.to(label, { duration: 0.25, color: "#ff0000" })
            gsap.to(label, { duration: 0.05, yoyo: true, repeat: 5, x: "+=20" })
            gsap.to(label, { duration: 0.05, yoyo: true, repeat: 5, x: "-=20" })
            gsap.to(label, { duration: 0.05, x: "0", delay: 0.25 })
        } else {
            gsap.to(label, { duration: 0.25, color: "#000000" })
            formValues.push(value)
        }
    });

    if (formValues.length == formInputs.length) sendForm(formValues)
})

const sendForm = (values) => {
    try {
        const docRef = addDoc(collection(db, "leads"), {
            nombre: values[0],
            telefono: values[1],
            email: values[2],
            credito: values[3],
            fecha: today,
            time: dateObj,
            to: ["silviaduran326@gmail.com", "lamberto170965@hotmail.com", "rcastroglez07@gmail.com"],
            message: {
                subject: "Nuevo prospecto!",
                html: `      
      nombre: ${values[0]} <br>
      telefono: ${values[1]} <br>
      email: ${values[2]} <br>
      modo de pago: ${values[3]} <br>
      fecha: ${today}`
            },
        });

        formInputs.forEach(e => {
            form.elements[e].value = ""
        })
        submit.value = "Enviado \u2705"
        gsap.to(submit, { duration: 0.25, backgroundColor: "#00e640" })
        setTimeout(() => {
            submit.value = "Empezar"
            gsap.to(submit, { duration: 0.25, backgroundColor: "#000000" })
        }, 1000);
        console.log("Document written!");
    } catch (e) {
        console.error("Error adding document: ", e);
    }
}